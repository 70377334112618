import React from "react"
import { Link } from "gatsby"
import Section from "../../../components/section/Section"
import decor from "../../../assets/images/decorations/main-page/decor.svg"

import Card1 from "../../../assets/images/decorations/main-page/ThirdSectionDecor1.inline.svg"
import Card2 from "../../../assets/images/decorations/main-page/ThirdSectionDecor2.inline.svg"
import Card3 from "../../../assets/images/decorations/main-page/ThirdSectionDecor3.inline.svg"
import Card4 from "../../../assets/images/decorations/main-page/ThirdSectionDecor4.inline.svg"
import Card5 from "../../../assets/images/decorations/main-page/ThirdSectionDecor5.inline.svg"

interface Imgs {
  [k: string]: () => JSX.Element
}

const images: Imgs = {
  Card1: () => <Card1 className="sova-card_third-section-bg" />,
  Card2: () => <Card2 className="sova-card_third-section-bg" />,
  Card3: () => <Card3 className="sova-card_third-section-bg" />,
  Card4: () => <Card4 className="sova-card_third-section-bg" />,
  Card5: () => <Card5 className="sova-card_third-section-bg" />,
}

const ThirdSection = ({ className, data }: { className: string; data: any }) => {
  const {
    content: {
      MainPageSectionThirdConfig: { cards, heading },
    },
    pageContext: { locale, lang },
  } = data.data

  return (
    <Section className={`${className} third-section`}>
      <h2 className="sova-h2 sova-h2_relative">
        {heading}
        <img src={decor} className="decor-decor sova-section-img" />
      </h2>
      <div className="sova-row-wrapped-spaced sova-row_third-section">
        {cards.map((el: any, i: number) => (
          <Link to={`${locale + el.href}`} key={i} className={`sova-card_third-section sova-card_third-section-${i + 1}`}>
            {images[el.img]()}
            <div className={`sova-card-content sova-card-content_third-section sova-card-content_third-section-${i + 1}`}>
              <p className={`sova-h3 sova-pb-16 sova-h3_mainPage-3 sova-h3_mainPage-3-${i + 1}-${lang}`}>{el.title}</p>
              {el.description && <p className="sova-p-tiny">{el.description}</p>}
            </div>
          </Link>
        ))}
      </div>
    </Section>
  )
}

export default ThirdSection
