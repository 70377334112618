import React from "react"

import MainPageSectionFirst from "./components/mainPageSectionFirst"
import MainPageSectionSecond from "./components/mainPageSectionSecond"
import MainPageSectionThird from "./components/mainPageSectionThird"

const sections = [
  { component: (key: number, className: string, data: any) => <MainPageSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <MainPageSectionSecond data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <MainPageSectionThird data={data} key={key} className={className} /> },
]

const MainPageSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-mainPage  sova-section-mainPage_${i}`, data))}</>
}

MainPageSections.displayName = "MainPageSections"

export default MainPageSections
