import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import MainPageSections from "../pagesStructures/mainPageLayout/mainPageSections"
import SEO from "../components/SEO/seo"

const IndexPage = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiMainPage.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="mainPage" mCustomClass="main-page">
      <SEO title={pageContent.content.title} />
      <MainPageSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  query MainPage($url: String) {
    strapiMainPage(url: { eq: $url }) {
      content {
        language
        content {
          title
          MainPageSectionFirstConfig {
            btnLink
            heading
            href
            preheading
            linkText
            startBtn
          }
          MainPageSectionSecondConfig {
            cards {
              buttonHref
              buttonText
              imgSrc
              text
              title
            }
            heading
          }
          MainPageSectionThirdConfig {
            cards {
              img
              description
              href
              title
            }
            heading
          }
        }
      }
    }
  }
`
