import React from "react"
import { Link } from "gatsby"
import { usePageContext } from "../../../components/pageContext/pageContext"
import Section from "../../../components/section/Section"
import Slider from "../../../components/slider/Slider"

import ic1 from "../../../assets/images/decorations/main-page/ic1.svg"
import ic2 from "../../../assets/images/decorations/main-page/ic2.svg"
import ic3 from "../../../assets/images/decorations/main-page/ic3.svg"
import ic4 from "../../../assets/images/decorations/main-page/ic4.svg"
import ic5 from "../../../assets/images/decorations/main-page/ic5.svg"
import icNet from "../../../assets/NET-logo.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  ic1: ic1,
  ic2: ic2,
  ic3: ic3,
  ic4: ic4,
  ic5: ic5,
  icNet: icNet,
}

const SecondSection = ({ className, data }: { className: string; data: any }) => {
  const { heading, cards } = data.data.content.MainPageSectionSecondConfig
  const { lang, locale } = usePageContext() as any
  const sliderOptions = cards.map((card: any) => {
    return { buttonHref: card.buttonHref, buttonText: card.buttonText, imgSrc: images[card.imgSrc], text: card.text, title: card.title }
  })

  return (
    <Section className={`${className} sova-second-section`}>
      <h2 className="sova-h2">{heading}</h2>
      <Slider options={sliderOptions.slice(0, 5)} className="sova-section-child sova-section-child_mainPage-slider" />
      <div className="sova-section-child sova-section-child_wrap sova-section-child_mainPage-2-container">
        {cards.map((el: any, i: number) => (
          <div key={i} className="sova-card">
            <div className={`sova-card-content sova-card-content-${lang} sova-card-content_with-btn-hover-visibility-effect`}>
              <img src={images[el.imgSrc]} className="sova-card-image sova-section-img sova-image_medium" />
              <p className="sova-card-header sova-card-header_mainPage-2 sova-p-medium">{el.title}</p>
              <p className="sova-card-description sova-p-tiny">{el.text}</p>
              <Link to={`${locale + el.buttonHref}`} className="sova-link_mainPage-2-learnMore-btn">
                <button className="sova-btn sova-btn_secondary sova-btn_small" value={el.title}>
                  {el.buttonText}
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default SecondSection
