import React from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import Section from "../../../components/section/Section"
import robot from "../../../assets/animation/MainRobotGgroup.json"
import brain from "../../../assets/animation/Brain.json"
import { usePageContext } from "../../../components/pageContext/pageContext"
import { Link } from "gatsby"

const MainSection = ({ className, data }: { className: string; data: any }) => {
  const { heading, preheading, startBtn, linkText, href, btnLink } = data.data.content.MainPageSectionFirstConfig
  const { locale } = usePageContext() as any

  return (
    <Section className={`${className} sova-fullscreen-height`}>
      <h1 className="sova-section-child sova-section-child_mainPage-0 sova-section-child_mainPage-0-heading sova-h1 sova-h1_centered">{heading}</h1>
      <h4 className="sova-section-child sova-section-child_mainPage-0 sova-h4">{preheading}</h4>
      <div className="sova-section-child-container sova-section-child-container_row">
        <a href={btnLink} target="blank" id="sova-mainPage-0_getStartedBtn">
          <button className="sova-btn sova-btn_primary sova-btn_big">{startBtn}</button>
        </a>
        <Link className="sova-section-child sova-section-child_mainPage-0-link sova-h4 sova-h4_link" to={`${locale}${href}`}>
          {linkText}
        </Link>
      </div>
      <div className="sova-section-container sova-section-container_row sova-section-container_mainPage-0">
        <div className="sova-section-child sova-section-img sova-section-child_mainPage-0-img sova-section-child_mainPage-0-img-0">
          <Player autoplay loop src={JSON.stringify(brain)} />
        </div>
        <div className="sova-section-child sova-section-img sova-section-child_mainPage-0-img sova-section-child_mainPage-0-img-1">
          <Player autoplay loop src={JSON.stringify(robot)} />
        </div>
      </div>
    </Section>
  )
}

export default MainSection
